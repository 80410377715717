import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import './AdminSidebar.css';

const checkPath = (location = {}, name) => {
  if (!location.pathname) return;
  const pathName = location.pathname.split('/')[2];
  return pathName === name;
}

const AdminSidebar = (props) => (
  <div className="AdminSidebar">
    <div className="ui left vertical labeled icon sidebar menu  visible">
      <Link to="/admin/order" className={`item ${checkPath(props.location, 'order') && 'active'} ${!props.isRole('admin') && 'hidden'}`}>
        <i className="list icon" />
        Заказ
      </Link>
      <Link to="/admin/menu" className={`item ${checkPath(props.location, 'menu') && 'active'} ${!props.isRole('admin') && 'hidden'}`}>
        <i className="utensils icon" />
        Меню
      </Link>
      <Link to="/admin/orderReports" className={`item ${checkPath(props.location, 'orderReports') && 'active'} ${!props.isRole('admin') && 'hidden'}`}>
        <i className="clipboard outline icon" />
        Отчеты по Заказам
      </Link>
      <Link to="/admin/scheduleVacation" className={`item ${checkPath(props.location, 'scheduleVacation') && 'active'}`}>
        <i className="chart bar icon" />
        Графики отпусков
      </Link>
      <Link to="/admin/settings" className={`item ${checkPath(props.location, 'settings') && 'active'} ${!props.isRole('admin') && 'hidden'}`}>
        <i className="settings icon" />
        Настройки
      </Link>
      <Link to="/admin/phones" className={`item ${checkPath(props.location, 'phones') && 'active'} ${!props.isRole(['admin', 'manager', 'hrmanager', 'hr']) && 'hidden'}`}>
        <i className="phone icon" />
        Телефонная книга
      </Link>
      <Link to="/admin/calendar" className={`item ${checkPath(props.location, 'calendar') && 'active'} ${!props.isRole(['admin', 'manager', 'hrmanager', 'hr']) && 'hidden'}`}>
        <i className="calendar icon" />
        Производ ственный календарь
      </Link>
    </div>
  </div>
);
AdminSidebar.propTypes = {
  location: PropTypes.shape().isRequired,
  isRole: PropTypes.func.isRequired,
}
export default withRouter(AdminSidebar);
