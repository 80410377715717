import React, { useState, useEffect, useCallback } from 'react';
import { Query, ApolloConsumer, Mutation } from 'react-apollo';
import { useQuery, ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import './Home.css';
import { PAGINATION_USERS, ALL_USERS, NEWS, BEST_USER } from '../../gql';
import { UserContext, HomeUserItem, SideColumnWithMobileBottomLine, Loader, Modal, UsersSelector } from '../../components';
import DuniceStarsBlock from '../../components/DuniceStarsBlock';
import { ADD_USER_STAR } from '../../gql/bestUser/mutation';

const linkify = (text) => {
  return text
    .split(/(\[[^\]]+\]\(https?:\/\/[^\)]+\))/ig)
    .map((part) => {
      const splittedPart = part.split(/\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/ig);
      if (splittedPart.length === 1) return part;
      const [_, name, link] = splittedPart;
      return <a key={link} href={link} target="_blank">{name}</a>
    });
};

const SideInfoBlock = () => {
  const sideMenuList = [
    {
      name: 'homeNews',
      title: 'Новости IBS Dunice',
    }];
  const news = (
    <Query
      query={NEWS}
      variables={{ hide: false }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) throw new Error(error);
        return (
          <div className="ui raised segment home-info-block">
            <h3>Наша миссия - способствовать бизнес успеху наших клиентов.</h3>
            <h2>Новости IBS Dunice</h2>
            {data.news.length < 0 ? (
              <div>нет новостей</div>
            ) : (
              data.news.map(news => (
                <div key={news.id}>
                  <h3>{news.title}</h3>
                  <div className="news-text">{linkify(news.text)}</div>
                  <em className="news-date">{moment(new Date(news.createdAt)).format('LL')}</em>
                </div>
              ))
            )}
          </div>
        )
      }}
    </Query>
  );
  return (
    <>
      <div className="ui column six wide">{news}</div>
      <SideColumnWithMobileBottomLine
        mainClass="six"
        menuList={sideMenuList}
      >
        {news}
      </SideColumnWithMobileBottomLine>
    </>
  );
}

const UserBlock = ({editable}) => {
  const { loading, error, data } = useQuery(BEST_USER, {
    // options
  });
  return (
    <div className='user-best-block'>
      <DuniceStarsBlock type={'best_employee'} data={data} editable={editable} />
      <DuniceStarsBlock type={'best_student'} data={data} editable={editable} />
    </div>
  )
}


const AdminManagerBlock = ({ isRole }) => {
  const [showNotActive, setShowNotActive] = useState(false);
  const [showBlocked, setShowBlocked] = useState(false);
  const [searchFilter, setShowFilter] = useState('');
  const { loading, error, data, fetchMore } = useQuery(PAGINATION_USERS, {
    // options
  });

  const toogleActive = () => {
    setShowNotActive(current => !current);
  }
  const toogleActiveBlocked = () => {
    setShowBlocked(current => !current);
  }
  const prepareData = list => list.filter(e => {
    const searchKeywords = searchFilter.toLowerCase().replace(/ё/g, 'е').split(/\s+/).filter(Boolean);
    const nameParts = [e.firstName, e.lastName].map(v => v.toLowerCase().replace(/ё/g, 'е'));
    // нет ни одного keyword который не содержит ни одной части имени пользователя
    return !_.some(searchKeywords, sk => !_.some(nameParts, np => np.includes(sk)))
  });


  const { paginationUsers: { results: allUsers = [], cursors = {} } = {} } = data || {};
  const users = allUsers ? allUsers.filter(item => item.status === 'active') : [];

  useEffect(() => {
    const destroyEffect = () => {

    }
    if (!cursors.hasNext) {
      return destroyEffect;
    }
    (async function () {
      try {
        await fetchMore({
          variables: { pageInfo: { after: cursors.after } },
          updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => {
            const previousSearch = previousResult.paginationUsers || {};
            const currentSearch = fetchMoreResult.paginationUsers || {};
            const previousNodes = previousSearch.results || [];
            const currentNodes = currentSearch.results || [];
            return {
              ...previousResult,
              paginationUsers: {
                ...previousSearch,
                results: [...previousNodes, ...currentNodes],
                cursors: currentSearch.cursors,
              },
            };
          },
        });
      } catch (error) {
        console.log('fetchMore err handle', error);
      } finally {
        return destroyEffect;
      }
    }());
  }, [cursors])


  if (loading) return <Loader />;
  if (error) return <Redirect to="/login" />;

  return (
    <>
      <div className="flex-block search-block">
        <div className={`ui icon input search-input `}>
          <input
            type="text"
            placeholder="Введите имя или фамилию сотрудника..."
            onChange={({ target: { value } }) => setShowFilter(value || '')}
          />
          <i className="search link icon" />
        </div>
      </div>
      <div className='card-container'>
        <div className="ui six cards padding-for-search-block">
          {prepareData(users).map(item => (
            <HomeUserItem user={item} key={item.id} />
          ))}
        </div>
        {isRole(['admin', 'manager', 'hrmanager', 'hr']) && <div className="ui accordion">
          <h3 className={`title ui horizontal divider header ${showNotActive && 'active'}`}
            onClick={toogleActive}>
            <i className="dropdown icon" />
            Еще неактивные пользователи
          </h3>
          {showNotActive &&
            <Query
              query={ALL_USERS}
              variables={{ filter: { status: 'inactive' } }}
            >
              {({ loading, error, data }) => {
                if (loading) return <Loader />;
                if (error) throw new Error(error);
                const inactiveUsers = data.allUsers ? data.allUsers.filter(item => item.status === 'inactive') : [];
                if (!inactiveUsers.length) return <p>Нет никого.</p>
                return (
                  <div className={`content ${showNotActive && 'active'}`}>
                    <div className="ui six cards">
                      {prepareData(inactiveUsers).map(item => (
                        <HomeUserItem user={item} key={item.id} />
                      ))}
                    </div>
                  </div>
                )
              }}
            </Query>
          }
        </div>}
        {isRole(['admin', 'hrmanager']) && <div className="ui accordion">
          <h3 className={`title ui horizontal divider header ${showBlocked && 'active'}`}
            onClick={toogleActiveBlocked}>
            <i className="dropdown icon" />
            Заблокированные пользователи
          </h3>
          {showBlocked &&
            <Query
              query={ALL_USERS}
              variables={{ filter: { status: 'blocked' } }}
            >
              {({ loading, error, data }) => {
                if (loading) return <Loader />;
                if (error) throw new Error(error);
                const blockedUsers = data.allUsers ? data.allUsers.filter(item => item.status === 'blocked') : [];
                return (
                  <div className={`content ${showBlocked && 'active'}`}>
                    <div className="ui six cards">
                      {prepareData(blockedUsers).map(item => (
                        <HomeUserItem user={item} key={item.id} />
                      ))}
                    </div>
                  </div>
                )
              }}
            </Query>
          }
        </div>}
      </div>
    </>
  ) 
}


function Home() {

  const getBlockByRole = (isRole) => {
    if (isRole(['admin'])) {
      return (
        <>
          <UserBlock editable/>
          <AdminManagerBlock isRole={isRole} />
        </>
      )
    } else if (isRole(['manager', 'hrmanager', 'hr'])) {
      return <AdminManagerBlock isRole={isRole} />
    } else {
      return <UserBlock />
    }
  }

  return (
    <UserContext.Consumer>
      {({ isRole }) => {
        return (
          <ApolloConsumer>
            {client => {
              return <ApolloHooksProvider client={client}>
                <div className="Home container-with-fixed-header">
                  <div className="html ui attached grid custom-reverse">
                    <div className="ui column ten wide">
                      {getBlockByRole(isRole)}
                    </div>

                    <SideInfoBlock />
                  </div>
                </div>
              </ApolloHooksProvider>
            }}
          </ApolloConsumer>
        )
      }
      }
    </UserContext.Consumer>
  );

}


export default Home;
