import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';
import InputMask from 'react-input-mask';

import {
  ProfileForm,
  UserContext,
  ImageUploader,
  ProfileRolesCard,
  ProfileStatusesCard,
  Loader,
} from '../../components/index';
import { USER, SAVE_AVATAR } from '../../gql';
import { titleCase } from '../../helpers';
import './Profile.css';


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: '',
      updateAvatar: false,
    };
  }

  onUploadFinish = (client) => {
    // client.resetStore();
    this.setState({ avatar: '', updateAvatar: false });
  }

  changeAvatar = (url) => {
    if (url) this.setState({ avatar: url, updateAvatar: url !== this.state.avatar });
  }

  renderInfoBlock = (profileOwner) => {
    if (profileOwner.status !== 'inactive' && profileOwner.status !== 'blocked' && profileOwner.avatar) return null;
    return (
      <div className="margin-bottom">
        {profileOwner.status === 'inactive'
        && (
        <div className="ui floating message warning">
          <div className="header">
            Этот пользователь еще неактивен!
          </div>
          <p>Подождите немного или попросите менеджера активировать этот аккаунт!</p>
        </div>
        )
        }
        {profileOwner.status === 'blocked'
        && (
        <div className="ui floating message error">
          <div className="header">
            Этот пользователь Заблокирован!
          </div>
          <p>Вы не можете редактировать заблокированный профиль пока не разблокируете.</p>
          <p>
Дата блокировки:&nbsp;
            {moment(profileOwner.blockedAt || profileOwner.updatedAt).format('LL')}
          </p>
        </div>
        )
        }
        {!profileOwner.avatar
        && (
        <div className="ui floating message warning">
          <div className="header">
            Еще не добавлена аватарка!
          </div>
          <p>Пожалуйста добавьте свое фото к аккаунту!</p>
        </div>
        )
        }
      </div>
    );
  }

  render() {
    const userId = this.props.match.params.id;
    return (
      <Query
        query={USER}
        variables={{ id: userId }}
      >
        {({
          client, loading, error, data,
        }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);
          const profileOwner = data.user;

          return (
            <UserContext.Consumer>
              {({ user, isRole }) => (
                <div className="Profile container-with-fixed-header">
                  {(profileOwner.id === user.id || isRole(['admin', 'manager', 'hrmanager', 'hr'])) && this.renderInfoBlock(profileOwner)}

                  <div className="ui column grid">
                    <div className={`column four wide content-center ${(!isRole(['admin', 'manager', 'hrmanager', 'hr']) && profileOwner.id !== user.id) && 'horizontal-card'}`}>
                      <div className="ui card main-info">
                        <div className="ui card main-info-image">
                          <div className="image">
                            <img src={this.state.avatar || profileOwner.avatar || '/assets/images/image.png'} alt="avatar" />
                          </div>
                          {((profileOwner.id === user.id && !profileOwner.avatar) || isRole(['admin', 'manager', 'hrmanager', 'hr'])) && profileOwner.status !== 'blocked'
                            && (
                            <div className="extra content">
                              <ImageUploader
                                onChange={this.changeAvatar}
                                imageType="avatar"
                              />
                              { this.state.updateAvatar
                                && (
                                <div className="ui two buttons">
                                  <Mutation
                                    mutation={SAVE_AVATAR}
                                    onCompleted={() => this.onUploadFinish(client)}
                                    onError={() => this.onUploadFinish(client)}
                                  >
                                    {saveAvatar => (
                                      <div
                                        className="ui basic green button"
                                        onClick={() => saveAvatar({
                                          variables: {
                                            user: {
                                              avatar: this.state.avatar,
                                              id: userId,
                                            },
                                          },
                                        })
                                          }
                                        role="button"
                                        tabIndex={0}
                                      >
                                            Сохранить
                                      </div>
                                    )}
                                  </Mutation>

                                  <div
                                    className="ui basic red button"
                                    onClick={() => this.setState({ updateAvatar: false, avatar: '' })}
                                    role="button"
                                    tabIndex={0}
                                  >
                                        Отмена
                                  </div>
                                </div>
                                )
                              }
                            </div>
                            )
                          }
                        </div>
                        <div className="ui card main-info-description">
                          <div className="content">
                            <a className="header">
                              {profileOwner.firstName}
                              {' '}
                              {profileOwner.lastName}
                            </a>
                            {(profileOwner.id === user.id || isRole(['admin', 'manager', 'hrmanager', 'hr']))
                              ? (
                                <React.Fragment>
                                  <div className="meta">
                                  Присоедился в
                                    {' '}
                                    <b>{moment(profileOwner.createdAt).format('YYYY')}</b>
                                    {' '}
году.
                                  </div>
                                  <div className="meta">
                                  А если точнее, то
                                    {' '}
                                    <b>{moment(profileOwner.createdAt).format('DD MMMM')}</b>
.
                                  </div>
                                  {profileOwner.status !== 'blocked'
                                && (
                                <React.Fragment>
                                  <div className="meta">
                                    Отдел
                                    {' '}
                                    <b>{profileOwner.companyName}</b>
.
                                  </div>
                                  <div className="meta">
                                    Офис
                                    {' '}
                                    <b>{profileOwner.office}</b>
.
                                  </div>
                                </React.Fragment>
                                )
                                }
                                  <div className="description">
                                    {titleCase(profileOwner.role.rusName)}
                                  </div>
                                </React.Fragment>
                              )
                              : (
                                <React.Fragment>
                                  {profileOwner.status !== 'blocked'
                                  && (
                                  <div className="meta">
                                    Офис
                                    {' '}
                                    <b>{profileOwner.office}</b>
.
                                  </div>
                                  )
                                }
                                </React.Fragment>
                              )
                            }
                          </div>
                          {(profileOwner.id === user.id || isRole(['admin', 'manager', 'hrmanager', 'hr']))
                          && (
                          <div className="extra content">
                            <a>
                              <i className="phone icon" />
                              <InputMask
                                style={{ border: 0 }}
                                disabled
                                mask="+7 (999) 999-99-99"
                                value={profileOwner.phone}
                              />
                            </a>
                          </div>
                          )
                          }
                        </div>

                      </div>
                      {(isRole(['admin', 'manager', 'hrmanager', 'hr']) && user.id !== profileOwner.id)
                        && (
                        <div className={`profile-sub-card ${isRole(['hr']) && 'hr'}`}>
                          <ProfileStatusesCard profileOwner={profileOwner} />
                          <ProfileRolesCard profileOwner={profileOwner} />
                        </div>
                        )
                      }

                    </div>
                    {(profileOwner.id === user.id || isRole(['admin', 'manager', 'hrmanager', 'hr'])) && <ProfileForm profileOwner={profileOwner} client={client} />}
                  </div>
                </div>
              )}
            </UserContext.Consumer>
          );
        }}
      </Query>
    );
  }
}

export default Profile;
