import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';

import { Logout, UserContext } from '../index';
import { COMMON_SUBSCRIPTION, HEADER_BADGES } from '../../gql';
import { isTouchDevice } from '../../helpers';
import { REQUEST_STATUSES, VACATION_STATUSES } from '../../environment';

import './Header.css';
import BurgerMenuIcon from './components/BurgerMenuIcon/BurgerMenuIcon';

const eventName = isTouchDevice() ? 'touchend' : 'mousedown';


const checkId = (location = {}, id) => {
  if (!location.pathname) return;
  const pathId = location.pathname.split('/')[2];
  return pathId === id;
};


const menuData = [
  {
    position: 1,
    name: 'sso',
    title: 'SSO',
    item: 'sso',
    link: 'https://sso.space.dunice.net', 
    imglink: 'https://sso.space.dunice.net/favicon.svg',
  },
  {
    position: 2,
    name: 'menu',
    title: 'Меню',
    item: 'dinner',
  },
  {
    position: 3,
    name: 'order',
    title: 'Заказ',
    item: 'order',
  },
  {
    position: 4,
    name: 'request',
    title: 'Заявки',
    label: true,
    item: 'request',
  },
  {
    position: 5,
    name: 'sick',
    title: 'Я заболел',
    item: 'hospital',
  },
  {
    position: 6,
    name: 'vacation',
    title: 'В отпуск',
    label: true,
    item: 'plane',
  },
  {
    position: 7,
    name: 'event',
    title: 'События',
    label: true,
    url: 'https://events.space.dunice.net',
    item: 'event',
  },
  {
    position: 8,
    name: 'handbook',
    title: 'Handbook',
    label: true,
    item: 'handbook',
    submenu: [
      {
        position: 1,
        name: 'handbook-dunice',
        title: 'Головной офис',
        item: 'handbook-dunice',
      },
      {
        position: 2,
        name: 'handbook-italyan',
        title: 'Академия на Итальянском',
        item: 'handbook-italyan',
      },
      {
        position: 3,
        name: 'handbook-academy',
        title: 'IBS Dunice Академия',
        item: 'handbook-academy',
      },
      {
        position: 4,
        name: 'handbook-emergency',
        title: 'Поведение при ЧС',
        item: 'handbook-emergency',
      },
    ],
  },
  {
    position: 9,
    name: 'admin',
    title: 'Админка',
    item: 'staff',
  },
];



function Submenu({ data, handleCloseToc, checkPath }) {
  const { submenu } = data;
  // const onMouseEnter = (e) => {}
  const itemActiveClass = _.some(submenu, sub => checkPath(sub.name)) ? 'active' : '';
  return  (
    <div
      className={`item ${itemActiveClass}`}
      // onMouseEnter={onMouseEnter}
    >
      <img className="ui menu-item" src={`/assets/icons/${data.item}.svg`} alt="item" />
      <div className="menu-title">{data.title}</div>
      <ul className="submenu">
        {submenu.map(sub => {
          return <li
            key={sub.position}
            className="submenu__item"
          >
            <Link
              to={`/${sub.name}`}
              className={`submenu__item_link ${checkPath(sub.name) && 'active'}`}
              onClick={handleCloseToc}
            >{sub.title}</Link>
          </li>;
        })}
      </ul>
    </div>
  );
}
class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenToc: false,
    };
  }

  componentDidMount() {
    document.addEventListener(eventName, this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener(eventName, this.handleClick, false);
    if (this.unsubscribe) this.unsubscribe();
  }

  checkPath = (name) => {
    const { location } = this.props;
    if (!location.pathname) return;
    const pathName = location.pathname.split('/')[1];
    return pathName === name;
  };


  prepareBadges = (subscribeToMore, data) => {
    const { headerBadges: { eventsCount = 0, requestsCount = 0, vacationsCount = 0 } = {} } = data;

    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = subscribeToMore({
      document: COMMON_SUBSCRIPTION,

      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const {
          commonSubscription: {
            event,
            vacation,
            request,
          } = {},
        } = subscriptionData.data;

        switch (true) {
          case !!(event && event.id):
            return Object.assign({}, prev, {
              headerBadges: { ...prev.headerBadges, eventsCount: eventsCount + 1 },
            });
          case !!(vacation && vacation.id):
            const incrementV = vacation.status === VACATION_STATUSES.waiting ? 1 : -1;
            return Object.assign({}, prev, {
              headerBadges: { ...prev.headerBadges, vacationsCount: vacationsCount + incrementV },
            });
          case !!(request && request.id):
            const incrementR = request.status === REQUEST_STATUSES.waiting ? 1 : -1;
            return Object.assign({}, prev, {
              headerBadges: { ...prev.headerBadges, requestsCount: requestsCount + incrementR },
            });
          default: return prev;
        }
      },
    });

    return { eventsCount, requestsCount, vacationsCount };
  }

  handleClick = (e) => {
    if (this.state.isOpenToc) {
      if (this.toc.contains(e.target) || this.tocIcon.contains(e.target)) return;
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isOpenToc: false });
    }
  }

  handleCloseToc = () => this.state.isOpenToc && this.setState({ isOpenToc: false })

  handleToggleLeftSidebar = () => {
    document.dispatchEvent(new Event('toggle-sidebar'));
  };

  renderMainMenuLinks = (badges) => (

    <UserContext.Consumer>
      {({ user, isRole }) => (
        <div className="flex-block">
          {menuData.map((data) => {
            if (!isRole(['admin', 'manager', 'hrmanager', 'hr', 'bookkeeper']) && data.name === 'admin') return null;
            if (data.submenu) {
              return <Submenu key={data.name} data={data} handleCloseToc={this.handleCloseToc} checkPath={this.checkPath}/>
            }
            if (data.name === 'sso') {
              return (
                <a className="item false" href={data.link} key={data.link}>
                  <img className="ui menu-item" src={data.imglink} alt="item" style={{width: 30 + 'px', height: 30 + 'px'}} />
                  <div className="menu-title" style={{ fontSize: 1.6 + 'em' }}>{data.title}</div>
                </a>
              )
            }
            if (data.url != null) {
              return (
                  <a href={data.url} className="item" key={data.position}>
                    <img className="ui menu-item" src={`/assets/icons/${data.item}.svg`} alt="item" />
                    <div className="menu-title">{data.title}</div>
                    {data.label && badges[`${data.name}sCount`] > 0 &&
                        <div className="floating ui yellow label">{badges[`${data.name}sCount`]}</div>
                    }
                  </a>
              );
            }
            return (
              <Link
                key={data.position}
                to={`/${data.name}`}
                className={`item ${this.checkPath(data.name) && 'active'}`}
                onClick={this.handleCloseToc}
              >
                <img className="ui menu-item" src={`/assets/icons/${data.item}.svg`} alt="item" />
                <div className="menu-title">{data.title}</div>
                {data.label && badges[`${data.name}sCount`] > 0 &&
                  <div className="floating ui yellow label">{badges[`${data.name}sCount`]}</div>
                }
              </Link>
            );
          })}
          <div className="ui right menu inverted">
            <Link
              to={`/profile/${user.id}`}
              onClick={this.handleCloseToc}
              className={`item avatar ${this.checkPath('profile') && checkId(this.props.location, user.id) && 'active'}`}
            >
              <img className="ui right spaced avatar image" src={user.avatar || '/assets/images/avatars/defaultAvatar.png'} alt="avatar" />
              <div className="menu-title">Профиль</div>
            </Link>

          </div>
          <Logout />
        </div>
      )}
    </UserContext.Consumer>
  )

  render() {
    return (
      <Query
        query={HEADER_BADGES}
      >
        {({ subscribeToMore, data }) => {
          const badges = this.prepareBadges(subscribeToMore, data)
          return (
            <div className="Header">
              <div className={`sidebar-ground ${this.state.isOpenToc ? 'visible' : 'hidden'}`}>
                <div className={`ui vertical inverted sidebar menu right uncover ${this.state.isOpenToc ? 'visible' : 'hidden'}`} ref={e => this.toc = e} >
                  {this.renderMainMenuLinks(badges)}
                </div>
              </div>
              <div className="ui inverted vertical center aligned segment">
                <div className="ui container">
                  <div className="ui large secondary inverted pointing menu">
                    <div aria-hidden className='sidebar-left' onClick={this.handleToggleLeftSidebar}>
                      <BurgerMenuIcon size={32} />
                    </div> 
                    <Link to="/" >
                      <img src="/assets/images/logo_ibs_dunice_white.svg" className="Header-logo " alt="logo" />
                    </Link>
                    {this.renderMainMenuLinks(badges)}

                    <a className="toc item" onClick={() => this.setState({ isOpenToc: !this.state.isOpenToc })} ref={e => this.tocIcon = e}>
                      <i className="sidebar icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    );
  }
}

export default Header;
