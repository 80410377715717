import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { UserContext, SmsCode, UserStatistic, ProfileSalaryFormula } from '../..';
import { getErrorMessages, showErrors } from '../../../helpers';
import { EDIT_USER, CHANGE_PASSWORD } from '../../../gql';
import './ProfileForm.css';

const ERROR_TEXT = {
  passwordCompare: 'Пароли должны быть одинаковыми',
  onlyLetters: 'Используйте только русские буквы',
  phoneIncorrect: 'Используйте только цифры',
  removeSpace: 'Пожалуйста не используйте пробелы.',
  phoneNotValid: 'Некорректный номер.',
  onlyNumbers: 'Используйте только цифры.',
};
const PHONE_REGEXP = /^[0-9]+$/i;// ^[+]?[()\d]{5,25}$/;
const LETTER_REGEXP = /^[А-я,Ё,ё]+$/i;
const NUMBER_REGEXP = /^[0-9]+$/i;

class ProfileForm extends Component {
  static propTypes = {
    profileOwner: PropTypes.shape().isRequired,
    client: PropTypes.shape().isRequired,
  }

  constructor(props) {
    super(props);
    const form = new Map();
    form.set('login', props.profileOwner.login);
    form.set('firstName', props.profileOwner.firstName);
    form.set('lastName', props.profileOwner.lastName);
    form.set('phone', props.profileOwner.phone);
    form.set('companyName', props.profileOwner.companyName);
    form.set('office', props.profileOwner.office);
    form.set('priority', props.profileOwner.priority);
    this.state = {
      formError: new Map(),
      form,
      tabIndex: 0,
      showInfo: false,
    };
  }

  onSubmit = (e, editUser, role) => {
    e.preventDefault();
    if (this.state.formError.size || !this.formIsChanged(role)) return;

    editUser({
      variables: {
        user: {
          id: this.props.profileOwner.id,
          login: this.state.form.get('login'),
          firstName: this.state.form.get('firstName'),
          lastName: this.state.form.get('lastName'),
          phone: this.state.form.get('phone'),
          companyName: this.state.form.get('companyName'),
          office: this.state.form.get('office'),
          code: +this.state.form.get('code') || 0,
          priority: this.state.form.get('priority'),
        },
      },
    });
  }

  onChangePassword = (e, changePassword) => {
    e.preventDefault();
    if (this.state.formError.size) return;

    changePassword({
      variables: {
        user: {
          id: this.props.profileOwner.id,
          password: this.state.form.get('password'),
          newPassword: this.state.form.get('newPassword'),
        },
      },
    });
  }

  onError = (error) => {
    const errorMessage = getErrorMessages(error);
    const { formError } = this.state;
    formError.set('response', errorMessage[0]);
    this.setState({ formError });
  }

  isSpaceEnable = value => value !== value.replace(/\s/g, '');

  validate = ({ target: { name, value } }) => {
    const { formError, form } = this.state;
    form.set(name, value);
    formError.has('response') && formError.delete('response');

    if (!value) {
      formError.has(name) && formError.delete(name);
      form.delete(name);
    } else if (this.isSpaceEnable(value) && name !== 'phone') {
      formError.set(name, ERROR_TEXT.removeSpace);
    } else {
      switch (name) {
        case 'confirmPassword':
        case 'newPassword':
          (form.has('confirmPassword') && form.get('confirmPassword') !== form.get('newPassword')) ?
            formError.set('confirmPassword', ERROR_TEXT.passwordCompare) : formError.delete('confirmPassword');
          break;
        case 'firstName':
        case 'lastName':
          !LETTER_REGEXP.test(form.get(name)) ?
            formError.set(name, ERROR_TEXT.onlyLetters) :
            formError.delete(name);
          break;
        case 'phone':
          form.set(name, value.replace(/\D/g, '').substring(1));
          form.get(name).length === 10 ?
            formError.delete(name) :
            formError.set(name, ERROR_TEXT.phoneNotValid);
          break;
        case 'priority':
          !NUMBER_REGEXP.test(form.get(name)) ?
            formError.set(name, ERROR_TEXT.onlyNumbers) :
            formError.delete(name), form.set(name, Number(value));
          break;
        default:
          formError.has(name) && formError.delete(name);
      }
    }

    this.setState({ form, formError });
  }
  phoneIsChanged = () => {
    const validPhone = !this.state.formError.has('phone') && this.state.form.has('phone');
    return validPhone && this.state.form.get('phone') !== this.props.profileOwner.phone;
  }

  formIsChanged = (role) => {
    const isNewPhone = this.phoneIsChanged() && (this.state.form.get('code') || role);
    const isNewFirstName = this.state.form.get('firstName') !== this.props.profileOwner.firstName;
    const isNewLastName = this.state.form.get('lastName') !== this.props.profileOwner.lastName;
    const isNewLogin = this.state.form.get('login') !== this.props.profileOwner.login;
    const isNewCompanyName = this.state.form.get('companyName') !== this.props.profileOwner.companyName;
    const isNewOffice = this.state.form.get('office') !== this.props.profileOwner.office;
    const isNewPriority = this.state.form.get('priority') !== this.props.profileOwner.priority;
    return isNewPhone || isNewFirstName || isNewLastName || isNewLogin || isNewOffice || isNewCompanyName || isNewPriority;
  }

  checkPasswords = (isValidRole) => {
    const oldPassword = isValidRole || this.state.form.get('password');
    const newPassword = this.state.form.get('newPassword') && this.state.form.get('confirmPassword') === this.state.form.get('newPassword');
    return oldPassword && newPassword;
  }

  toggleInfo = () => this.setState({ showInfo: !this.state.showInfo })

  render() {
    return (
      <div className={`ProfileForm column twelve wide ${this.props.profileOwner.status === 'blocked' && 'custom-disabled'}`}>
        <div className="ui pointing menu item four">
          <div
            className={`item ${this.state.tabIndex === 0 && 'active'}`}
            onClick={() => this.setState({ tabIndex: 0, formError: new Map() })}
          >
            <h4 className="ui header">
              <div className="content">
                Персональные данные
              </div>
            </h4>
          </div>
          <div
            className={`item ${this.state.tabIndex === 1 && 'active'}`}
            onClick={() => this.setState({ tabIndex: 1, formError: new Map() })}
          >
            <h4 className="ui header">
              <div className="content">
                Сменить пароль
              </div>
            </h4>
          </div>
          <div
            className={`item ${this.state.tabIndex === 2 && 'active'}`}
            onClick={() => this.setState({ tabIndex: 2, formError: new Map() })}
          >
            <h4 className="ui header">
              <div className="content">
                Немного цифр
              </div>
            </h4>
          </div>
          <div
            className={`item ${this.state.tabIndex === 3 && 'active'}`}
            onClick={() => this.setState({ tabIndex: 3, formError: new Map() })}
          >
            <h4 className="ui header">
              <div className="content">
                Детализация зарплаты
              </div>
            </h4>
          </div>
        </div>
        <div className={`ui tab segment flex-block ${this.state.tabIndex === 0 && 'active'}`}>
          <a className="ui blue right corner label" onClick={this.toggleInfo}>
            <i className="question icon pointer" />
          </a>
          <Mutation
            mutation={EDIT_USER}
            onError={(error) => {
              this.state.form.set('code', '')
              this.setState({ form: this.state.form });
              this.onError(error)
            }}
            onCompleted={() => {
              this.state.form.set('code', '')
              this.setState({ form: this.state.form });
            }}
          >
            {(editUser, { loading, data }) => (
              <UserContext.Consumer>
                {({ user, isRole }) => (
                  <React.Fragment>
                    <form
                      className={`ui large form  ${this.state.formError.size && 'error'}`}
                      onSubmit={e => this.onSubmit(e, editUser, (isRole(['admin', 'manager', 'hrmanager', 'hr']) && user.id !== this.props.profileOwner.id))}
                    >
                      <div className={`field ${this.state.formError.has('login') && 'error'}`}>
                        <label htmlFor="login">Логин</label>
                        <div className={`ui left icon input ${!isRole(['admin', 'manager', 'hrmanager', 'hr']) && 'disabled'}`}>
                          <i className="user icon" />
                          <input
                            type="text"
                            name="login"
                            value={this.state.form.get('login')}
                            onChange={this.validate}
                          />
                        </div>
                      </div>
                      <div className={`field ${this.state.formError.has('firstName') && 'error'}`}>
                        <label htmlFor="firstName">Имя</label>
                        <div className={`ui left icon input ${!isRole(['admin', 'manager', 'hrmanager', 'hr']) && 'disabled'}`}>
                          <i className="user icon" />
                          <input
                            type="text"
                            name="firstName"
                            value={this.state.form.get('firstName')}
                            onChange={this.validate}
                          />
                        </div>
                      </div>
                      <div className={`field ${this.state.formError.has('lastName') && 'error'}`}>
                        <label htmlFor="lastName">Фамилия</label>
                        <div className={`ui left icon input ${!isRole(['admin', 'manager', 'hrmanager', 'hr']) && 'disabled'}`}>
                          <i className="user icon" />
                          <input
                            type="text"
                            name="lastName"
                            value={this.state.form.get('lastName')}
                            onChange={this.validate}
                          />
                        </div>
                      </div>
                      <div className={`field ${this.state.formError.has('companyName') && 'error'}`}>
                        <label htmlFor="companyName">Отдел</label>
                        <div className={`ui left icon input ${!isRole(['admin', 'manager', 'hrmanager', 'hr']) && 'disabled'}`}>
                          <i className="building icon" />
                          <input
                            type="text"
                            name="companyName"
                            value={this.state.form.get('companyName')}
                            onChange={this.validate}
                          />
                        </div>
                      </div>
                      <div className={`field ${this.state.formError.has('office') && 'error'}`}>
                        <label htmlFor="office">Офис</label>
                        <div className={`ui left icon input ${!isRole(['admin', 'manager', 'hrmanager', 'hr']) && 'disabled'}`}>
                          <i className="map marker alternate icon" />
                          <input
                            type="text"
                            name="office"
                            value={this.state.form.get('office')}
                            onChange={this.validate}
                          />
                        </div>
                      </div>
                      <div className={`field ${this.state.formError.has('phone') && 'error'}`}>
                        <label htmlFor="phone">Телефон</label>
                        <div className="ui left icon input">
                          <i className="phone icon" />
                          <InputMask
                            name="phone"
                            mask="+7 (999) 999-99-99"
                            value={this.state.form.get('phone')}
                            onChange={this.validate}
                            placeholder="Мобильный телефон"
                          />
                        </div>
                      </div>
                      <div className="field">
                        <SmsCode
                          disabled={!this.phoneIsChanged()}
                          code={this.state.form.get('code')}
                          onChange={this.validate}
                          onError={this.onError}
                          phone={this.state.form.get('phone')}
                        />
                      </div>
                      {
                        isRole(['admin'])
                        && (
                          <div className={`field ${this.state.formError.has('priority') && 'error'}`}>
                            <label htmlFor="priority">Приоритет</label>
                            <div className="ui left icon input">
                              <i className="exclamation circle alternate icon" />
                              <input
                                type="text"
                                name="priority"
                                value={this.state.form.get('priority')}
                                onChange={this.validate}
                              />
                            </div>
                          </div>
                        )
                      }

                      <button
                        type="submit"
                        className={`ui large green submit button ${loading && 'loading'} ${(loading && this.state.formError.size || !this.formIsChanged(isRole(['admin', 'manager', 'hrmanager', 'hr']) && user.id !== this.props.profileOwner.id)) && 'disabled'}`}
                      >
                        Сохранить изменения
                      </button>
                      <div className="ui error message">
                        <ul className="list">
                          {showErrors(this.state.formError)}
                        </ul>
                      </div>

                    </form>
                    <div className={`ui message info ${this.state.showInfo && 'absolute-block'}`}>
                      {isRole(['admin', 'manager', 'hrmanager', 'hr']) &&
                        <div>
                          <p>Только администратор или менеджер могут редактировать данные пользователя.</p>
                          <p>А также поменять телефон пользователя без смс кода.</p>
                          <div className="bottom-info-blockquote">
                            <p><q>С большой силой приходит и большая ответственность.</q></p>
                            <p><cite>- дядя Бэн</cite></p>
                          </div>
                        </div>
                      }

                      {!isRole(['admin', 'manager', 'hrmanager', 'hr']) &&
                        <div>
                          <p>Во избежание путаницы или неуместных шуток вы не можете менять свои личные данные.</p>
                          <p>Для любых изменений обратитесь к своему руководителю.</p>
                          <p>При смене телефона вам будет выслан смс код на новый номер.</p>
                        </div>
                      }
                    </div>
                  </React.Fragment>
                )}
              </UserContext.Consumer>
            )}
          </Mutation>
        </div>

        <div className={`ui tab segment flex-block ${this.state.tabIndex === 1 && 'active'}`}>
          <a className="ui blue right corner label" onClick={this.toggleInfo}>
            <i className="question icon pointer" />
          </a>
          <Mutation mutation={CHANGE_PASSWORD} onError={this.onError}>
            {(changePassword, { loading, data }) => {
              if (data) {
                this.props.client.resetStore();
              }
              return (
                <UserContext.Consumer>
                  {({ user, isRole }) => (
                    <React.Fragment>
                      <form
                        className={`ui large form  ${this.state.formError.size && 'error'}`}
                        onSubmit={e => this.onChangePassword(e, changePassword)}
                      >
                        <div className={`field ${this.state.formError.has('password') && 'error'}`}>
                          <label htmlFor="password">Пароль</label>
                          <div className={`ui left icon input ${isRole(['admin', 'manager', 'hrmanager', 'hr']) && user.id !== this.props.profileOwner.id && 'disabled'}`}>
                            <i className="user icon" />
                            <input
                              type="password"
                              name="password"
                              value={this.state.form.get('password')}
                              onChange={this.validate}
                            />
                          </div>
                        </div>

                        <div className={`field ${this.state.formError.has('newPassword') && 'error'}`}>
                          <label htmlFor="newPassword">Новый пароль</label>
                          <div className="ui left icon input">
                            <i className="lock icon" />
                            <input
                              type="password"
                              name="newPassword"
                              value={this.state.form.get('newPassword')}
                              onChange={this.validate}
                            />
                          </div>
                        </div>
                        <div className={`field ${this.state.formError.has('confirmPassword') && 'error'}`}>
                          <label htmlFor="confirmPassword">Повторите новый пароль</label>
                          <div className="ui left icon input">
                            <i className="lock icon" />
                            <input
                              type="password"
                              name="confirmPassword"
                              value={this.state.form.get('confirmPassword')}
                              onChange={this.validate}
                            />
                          </div>
                        </div>
                        <button
                          type="submit"
                          className={`ui large green submit button ${loading && 'loading'} ${(loading || !this.state.form.has('newPassword') || this.state.formError.size || !isRole(['admin', 'manager', 'hrmanager', 'hr'])) && 'disabled'}`}
                        >
                          Сохранить пароль
                        </button>

                        <div className="ui error message">
                          <ul className="list">
                            {showErrors(this.state.formError)}
                          </ul>
                        </div>

                      </form>
                      <div className={`ui message info ${this.state.showInfo && 'absolute-block'}`}>
                        {isRole(['admin', 'manager', 'hrmanager', 'hr']) &&
                          <div>
                            <p>Вы можете изменить пароль пользователя не вводя текущий пароль.</p>
                            <p>При смене пароля не забудьте оповестить об этом пользователя.</p>
                            <div className="bottom-info-blockquote">
                              <p><q>С большой силой приходит и большая ответственность.</q></p>
                              <p><cite>- дядя Бэн</cite></p>
                            </div>
                          </div>
                        }

                        {!isRole(['admin', 'manager', 'hrmanager', 'hr']) &&
                          <div>
                            <p>Используйте латинские буквы и цифры чтобы создать надежный пароль.</p>
                            <p>Если вы забыли текущий пароль вы можете воспользоваться <Link to="/forgot_password" >восстановлением пароля</Link> или обратиться к своему руководителю.</p>
                          </div>
                        }

                      </div>
                    </React.Fragment>
                  )}
                </UserContext.Consumer>
              );
            }}
          </Mutation>
        </div>
        <div className={`ui tab segment flex-block ${this.state.tabIndex === 2 && 'active'}`}>
          {this.state.tabIndex === 2 && <UserStatistic profileOwner={this.props.profileOwner} />}
        </div>
        <div className={`ui tab segment flex-block ${this.state.tabIndex === 3 && 'active'}`}>
          {this.state.tabIndex === 3 && <ProfileSalaryFormula profileOwner={this.props.profileOwner} />}
        </div>
      </div>
    );
  }
}

export default ProfileForm;
